import { Routes } from "@angular/router";
import { HomeComponent } from "./features/home";

import { APPROUTES } from "./app.routes.strings";
import { GEOCODE_ROUTES } from "./features/geocode/geocode.routes";
import { UnauthorizedComponent } from "./infrastructure/oidc/unauthorized/unauthorized.component";
import { ROUTING_ROUTES } from "./features/routing/routing.routes";
import { LOCATION_ROUTES } from "./features/locations/locations.routes";

export const ROUTES: Routes = [
  { path: APPROUTES.home, component: HomeComponent },

  // feature routes
  ...GEOCODE_ROUTES,
  ...ROUTING_ROUTES,
  ...LOCATION_ROUTES,

  // unauthorized
  { path: APPROUTES.unauthorized, component: UnauthorizedComponent },
  // else... 404 -> redirect home
  { path: APPROUTES.catchAll, redirectTo: "/" },
];
