import { Component } from "@angular/core";
import { Store, Select } from "@ngxs/store";
import { ReverseGeocode } from "./state/actions/ReverseGeocode";
import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { GeocodeState } from "./state/geocode.state";
import { Observable } from "rxjs";
import { Address } from "cluster";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "app/infrastructure/toastr/toastr.service";

import { Layer, marker, icon, LeafletMouseEvent } from "leaflet";
import { LeafletService } from "app/services/leaflet.service";

@Component({
  selector: "app-geocode",
  templateUrl: "./geocode.component.html",
  styleUrls: ["./geocode.component.scss"],
})
export class GeocodeComponent extends StateContainerComponent {
  @Select(GeocodeState.addresses)
  private stateAddresses$: Observable<Address[]>;
  public addresses: Address[] = [];

  /**
   * Default coordinates (inforit's location)
   */
  public coordinates = { latitude: 51.75437, longitude: 5.55221 };
  public callBackUrl = "";

  public mapReference: L.Map;
  public markers: Layer[] = [];
  public mergeAndApplyStyles = LeafletService.mergeAndGetStyles;
  public getLeafletOptions = LeafletService.getLeafletOptions;

  public constructor(
    public store: Store,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
    super();

    this.route.queryParams.subscribe((params) => {
      // if query params contain a longitude or latitude use it
      if (params.longitude || params.latitude) {
        this.coordinates = { ...this.coordinates, ...params };
        this.store.dispatch(new ReverseGeocode(this.coordinates.longitude, this.coordinates.latitude));
      }
      // if a callbackurl is available in the params we store it for later
      if (params.callBackUrl) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.callBackUrl = params.callBackUrl;
      }
    });
  }

  public onMapReady(map: L.Map) {
    this.mapReference = map;

    this.mapReference.on("contextmenu", (e: LeafletMouseEvent) => {
      this.markers = [];
      const aMarker = marker([e.latlng.lat, e.latlng.lng], {
        icon: icon({
          iconSize: [32, 32], // Height & width
          iconAnchor: [15, 30], // offset icon?
          iconUrl: "/assets/map-marker-icon.png",
        }),
      });
      this.markers.push(aMarker);

      this.coordinates.latitude = e.latlng.lat;
      this.coordinates.longitude = e.latlng.lng;
      this.store.dispatch(new ReverseGeocode(this.coordinates.longitude, this.coordinates.latitude));
    });

    setTimeout(() => {
      map.invalidateSize();
    }, 2000);
  }

  public updateCoordinates(lat: number, long: number) {
    this.coordinates.latitude = lat;
    this.coordinates.longitude = long;
  }

  public mapStateToProps(): void {
    this.bindSelectorToProperty(this.stateAddresses$, "addresses");
  }

  /**
   * Originally built for TF1 but can be useful for others.
   * In the params you can specify a callBackUrl, doing so reveals a button
   * which when clicked will send the response (the addresses json) to the callBackUrl
   */
  public respondToCallBackUrl() {
    if (this.callBackUrl) {
      // we have to subscribe,
      this.http.post(this.callBackUrl, JSON.stringify(this.addresses)).subscribe({
        next: () => {
          this.toastrService.success("Call successfully made to external application");
        },
        error: () => {
          this.toastrService.error("Call to external application failed, please consult the external party");
        },
      });
    }
  }
}
