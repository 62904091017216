/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit } from "@angular/core";
import { RoutingState } from "./state/routing.state";
import { Select, Store } from "@ngxs/store";
import { CallStatus } from "app/models/enums/httpCallStatus";
import { Observable } from "rxjs";
import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { ActivatedRoute } from "@angular/router";
import { CalculateRoute } from "./state/actions/CalculateRoute";
import { CalculatedRoute } from "./models/calculatedRoute";
import { Point } from "./models/point";
import { RoutingFilter } from "./models/routingFilter";
import { SetRoutingPoints } from "./state/actions/SetRoutingPoints";
import { UpdateRoutingFilter } from "./state/actions/UpdateRoutingFilter";
import { TunnelRestriction } from "./models/enums/tunnelRestriction";
import { Polygon as CustomPolygon } from "./models/polygon";

import { MatDialog } from "@angular/material/dialog";
import { AddLocationDialogComponent } from "./dialogs/addLocationDialog.component";

import { FeatureGroup, Layer, polyline } from "leaflet";
import { SimpleAddress } from "./models/simpleAddress";
import { SpeedProfile } from "./models/enums/speedProfile";
import { EmissionStandard } from "./models/enums/emissionStandard";
import { TollObject } from "./models/tollObject";
import { LeafletService } from "app/services/leaflet.service";

@Component({
  selector: "app-routing",
  templateUrl: "./routing.component.html",
  styleUrls: ["./routing.component.scss"],
})
export class RoutingComponent extends StateContainerComponent implements OnInit {
  public pendingState = CallStatus.pending;

  @Select(RoutingState.calculatedRoute)
  private stateCalculatedRoute$: Observable<CalculatedRoute>;
  public calculatedRoute: CalculatedRoute;

  @Select(RoutingState.points)
  private statePoints$: Observable<Point[]>;
  public points: Point[];

  @Select(RoutingState.filter)
  private stateFilter$: Observable<RoutingFilter>;
  public filter: RoutingFilter;

  @Select(RoutingState.operationState)
  private stateOperationState$: Observable<CallStatus>;
  public operationState: CallStatus = CallStatus.succeeded;

  public restrictions = TunnelRestriction;

  public distance;
  public time;
  public cost;

  public tollObjects;

  public fieldDefinitions = [
    {
      type: "select",
      name: "tunnelRestriction",
      disabled: false,
      placeholder: "Select a option",
      options: [
        { value: TunnelRestriction.A, viewValue: TunnelRestriction.A },
        { value: TunnelRestriction.B, viewValue: TunnelRestriction.B },
        { value: TunnelRestriction.C, viewValue: TunnelRestriction.C },
        { value: TunnelRestriction.D, viewValue: TunnelRestriction.D },
        { value: TunnelRestriction.E, viewValue: TunnelRestriction.E },
        { value: TunnelRestriction.F, viewValue: TunnelRestriction.F },
      ],
      value: null,
    },
    {
      type: "select",
      name: "speedProfile",
      disabled: false,
      placeholder: "Select a option",
      options: [
        { value: SpeedProfile.Truck40T, viewValue: SpeedProfile.Truck40T },
        { value: SpeedProfile.TruckFast, viewValue: SpeedProfile.TruckFast },
        { value: SpeedProfile.TruckSlow, viewValue: SpeedProfile.TruckSlow },
      ],
      value: null,
    },
    {
      type: "datepicker",
      name: "tollDate",
      disabled: false,
      placeholder: "Select a option",
      value: null,
    },
    {
      type: "select",
      name: "emissionStandard",
      label: "euroClass",
      disabled: false,
      placeholder: "Select a option",
      options: [
        { value: EmissionStandard.EURO_1, viewValue: EmissionStandard.EURO_1 },
        { value: EmissionStandard.EURO_2, viewValue: EmissionStandard.EURO_2 },
        { value: EmissionStandard.EURO_3, viewValue: EmissionStandard.EURO_3 },
        { value: EmissionStandard.EURO_4, viewValue: EmissionStandard.EURO_4 },
        { value: EmissionStandard.EURO_5, viewValue: EmissionStandard.EURO_5 },
        { value: EmissionStandard.EURO_6, viewValue: EmissionStandard.EURO_6 },
      ],
      value: null,
    },
    {
      type: "checkbox",
      name: "drawRoute",
      label: "Show route",
      color: "color",
      disabled: false,
      value: null,
    },
  ];

  public mapReference: L.Map;
  public markers: Layer[] = [];

  public mergeAndApplyStyles = LeafletService.mergeAndGetStyles;
  public getLeafletOptions = LeafletService.getLeafletOptions;
  public extraStyles = { height: "calc(100vh - 130px)", width: "80%" };

  public hasCompletedInitialCall = false;

  public constructor(public store: Store, public matDialog: MatDialog, private route: ActivatedRoute) {
    super();

    this.route.queryParams.subscribe((params) => {
      // if query params contain a request
      if (params.request) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const urlRequest: { points: SimpleAddress[] } = JSON.parse(atob(params.request));
        if (urlRequest.points) {
          this.hasCompletedInitialCall = true;
          const filter: RoutingFilter = {};

          const addPropertyIfExists = (property) => {
            if (urlRequest[property] !== undefined) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              filter[property] = urlRequest[property];
            }
          };

          addPropertyIfExists("tollDate");
          addPropertyIfExists("tunnelRestriction");
          addPropertyIfExists("speedProfile");
          addPropertyIfExists("emissionStandard");
          addPropertyIfExists("goodsType");
          addPropertyIfExists("vehicleType");
          addPropertyIfExists("drawRoute");

          this.store.dispatch(new SetRoutingPoints(urlRequest.points));
          this.store.dispatch(new UpdateRoutingFilter(filter, false));
        }
      }
    });
  }

  public ngOnInit() {
    super.ngOnInit();
    if (!this.hasCompletedInitialCall) {
      this.store.dispatch(new CalculateRoute());
    }
  }

  private drawMap(markers: SimpleAddress[], polygon: CustomPolygon) {
    this.markers = [];
    markers.forEach((position) => {
      this.markers.push(
        LeafletService.getMarkerWithBasicPopup(position.position.latitude, position.position.longitude, position.name)
      );
    });

    const lines = [];
    if (polygon && polygon.points && this.filter.drawRoute) {
      polygon.points.forEach((point) => {
        lines.push([point.latitude, point.longitude]);
      });
      const lineCollection = polyline(lines);
      this.markers.push(lineCollection);
      this.mapReference.fitBounds(lineCollection.getBounds());
    }
  }

  public onMapReady(map: L.Map) {
    this.mapReference = map;
    if (this.markers && this.markers.length > 0) {
      const bounds = new FeatureGroup(this.markers).getBounds();
      this.mapReference.fitBounds(bounds, { padding: [20, 20] });
    }

    setTimeout(() => {
      map.invalidateSize();
    }, 2000);
  }

  public openAddLocationDialog() {
    const dialogRef = this.matDialog.open(AddLocationDialogComponent);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.AddPoint(data);
      }
    });
  }

  public AddPoint(data) {
    const myPoints = [...this.points];
    myPoints.push(data);
    this.store.dispatch(new SetRoutingPoints(myPoints));
  }

  public handleSubmit(json) {
    this.store.dispatch(new UpdateRoutingFilter(JSON.parse(json)));
  }

  public onClickCalculateRoute() {
    this.store.dispatch(new CalculateRoute());
  }

  public SwitchPoints(oldPoint, newPoint) {
    const myPoints = [...this.points];
    myPoints[oldPoint] = this.points[newPoint];
    myPoints[newPoint] = this.points[oldPoint];
    this.store.dispatch(new SetRoutingPoints(myPoints));
  }

  public removeLocation(index) {
    const myPoints = [...this.points];
    myPoints.splice(index, 1);
    this.store.dispatch(new SetRoutingPoints(myPoints));
  }

  public convertToEuro(value) {
    const euro = value / 100;
    const cost = euro.toFixed(1) + " euro";
    return cost;
  }

  public convertToKilometers(value) {
    const km = value / 1000;
    const distance = km.toFixed(1) + " km";
    return distance;
  }

  public convertToMinutes(value) {
    const formatHHMMSS = (sec) => {
      const hours = Math.floor(sec / 3600);
      const minutes = Math.floor((sec - hours * 3600) / 60);
      const seconds = Math.floor(sec - hours * 3600 - minutes * 60);

      const addLeadingZeroIfBelow10 = (numberToCheck: number) =>
        numberToCheck < 10 ? 0 + numberToCheck : numberToCheck;

      return `${addLeadingZeroIfBelow10(hours)}:${addLeadingZeroIfBelow10(minutes)}:${addLeadingZeroIfBelow10(
        seconds
      )}`;
    };

    return formatHHMMSS(value);
  }

  /**
   * Check whether address is unique, if so add it to markers
   *
   * @param address
   */
  public isAddressUnique = (address: SimpleAddress, addressList: SimpleAddress[]) =>
    addressList.find(
      (e) => e.position.latitude === address.position.latitude && e.position.longitude === address.position.longitude
    ) === undefined;

  public mapStateToProps(): void {
    this.bindSelectorToProperty(this.stateCalculatedRoute$, "calculatedRoute", (val: CalculatedRoute) => {
      // markers to be drawn
      const markersToDraw: SimpleAddress[] = [];
      let polygonToDraw: CustomPolygon;
      const tollobjects: TollObject[] = [];

      if (val && val.countryInfos) {
        val.countryInfos.forEach((countryInfo) => {
          if (countryInfo.tollObjects) {
            this.tollObjects = [...tollobjects, ...countryInfo.tollObjects];
          }
        });
      }

      if (val) {
        // Meters to Kilometers
        this.distance = this.convertToKilometers(val.distance);
        // Seconds to minutes
        this.time = this.convertToMinutes(val.time);
        // Cents to euro
        this.cost = this.convertToEuro(val.cost);
      }

      if (val && val.sections) {
        /**
         * Check whether address is unique, if so add it to markers
         *
         * @param address
         */
        const addIfUnique = (address: SimpleAddress) => {
          if (
            markersToDraw.filter(
              (e) =>
                e.position.latitude === address.position.latitude && e.position.longitude === address.position.longitude
            ).length === 0
          ) {
            markersToDraw.push(address);
          }
        };

        // check all sections
        val.sections.forEach((section) => {
          addIfUnique(section.from);
          addIfUnique(section.to);
        });
      }

      if (val && val.polygon) {
        polygonToDraw = val.polygon;
      }

      this.drawMap(markersToDraw, polygonToDraw);

      return val;
    });

    this.bindSelectorToProperty(this.stateOperationState$, "operationState", (val: CallStatus) => {
      if (val === CallStatus.failed) {
        const markersToDraw = [];
        this.points.forEach((point: Point) => {
          const address = {
            position: point.position,
          } as SimpleAddress;

          if (this.isAddressUnique(address, markersToDraw)) {
            markersToDraw.push(address);
          }
        });
        this.drawMap(markersToDraw, undefined);
      }
      return val;
    });

    this.bindSelectorToProperty(this.statePoints$, "points");
    this.bindSelectorToProperty(this.stateFilter$, "filter", (val: RoutingFilter) => {
      this.fieldDefinitions[0].value = val.tunnelRestriction;
      this.fieldDefinitions[1].value = val.speedProfile;
      this.fieldDefinitions[2].value = new Date(val.tollDate);
      this.fieldDefinitions[3].value = val.emissionStandard;
      this.fieldDefinitions[4].value = val.drawRoute;

      return val;
    });
  }
}
