import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AppSettings } from "app/infrastructure/appsettings";
import { ToastrService } from "app/infrastructure/toastr/toastr.service";
import { CallStatus } from "app/models/enums/httpCallStatus";

import { tap } from "rxjs/internal/operators/tap";
import { Address } from "../models/Address";
import { ReverseGeocode } from "./actions/ReverseGeocode";
import GeocodeStateModel from "./models/GeocodeStateModel";

@State<GeocodeStateModel>({
  name: "geocode",
  defaults: {},
})
@Injectable()
export class GeocodeState {
  public constructor(private http: HttpClient, private toastrService: ToastrService) {}

  @Selector()
  public static operationState(state: GeocodeStateModel) {
    return state.operationState;
  }

  @Selector()
  public static addresses(state: GeocodeStateModel) {
    return state.addresses;
  }

  /**
   * get the estimate requests from the estimate requests service
   *
   * @param ctx
   * @param action
   */
  @Action(ReverseGeocode)
  public ReverseGeocode(ctx: StateContext<GeocodeStateModel>, action: ReverseGeocode) {
    ctx.patchState({
      operationState: CallStatus.pending,
    });

    const { root, reverseGeoCode } = AppSettings.settings.BackendPaths;
    let params = new HttpParams();

    params = params.set("Longitude", action.longitude.toString());
    params = params.set("Latitude", action.latitude.toString());

    return this.http
      .get(`${root}/${reverseGeoCode}`, {
        params,
        observe: "response",
      })
      .pipe(
        tap((response: HttpResponse<Address[]>) => {
          ctx.patchState({
            addresses: response.body,
            operationState: CallStatus.succeeded,
          });
        }, this.handleError.bind(this))
      );
  }

  /**
   * give a toast on error
   *
   * @param error
   */
  public handleError(error: HttpErrorResponse) {
    this.toastrService.error(error.message);
  }
}
