/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { tileLayer, latLng, marker, icon } from "leaflet";
import { AppSettings } from "app/infrastructure/appsettings";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LeafletService {
  public constructor() {}

  /**
   * Return a valid leafletOptions object
   */
  public static getLeafletOptions() {
    return {
      layers: [
        tileLayer(AppSettings.settings.Leaflet.tileLayer, {
          maxZoom: 18,
          attribution: AppSettings.settings.Leaflet.attribution,
        }),
      ],
      preferCanvas: true,
      zoom: 18,
      // center on inforit if no bounding box applied :)
      center: latLng(51.7543453, 5.5526647),
    };
  }

  /**
   * Form and return a leaflet-marker object with a text-filled popup
   *
   * @param latitude
   * @param longitude
   * @param text text in the popup
   * @param iconImageUrl image of the marker on the map
   */
  public static getMarkerWithBasicPopup(
    latitude,
    longitude,
    text,
    iconImageUrl = "/assets/map-marker-icon.png",
    anchorX?,
    anchorY?
  ) {
    const aMarker = marker([latitude, longitude], {
      icon: icon({
        iconSize: [25, 25], // Height & width
        iconAnchor: anchorX && anchorY ? [anchorX, anchorY] : [15, 20], // offset icon?
        iconUrl: iconImageUrl,
      }),
    });
    // add a popup which shows when mouse is over the icon and disappears when
    // mouse leaves
    aMarker.bindPopup(text);
    aMarker.on("mouseover", function() {
      this.openPopup();
    });
    aMarker.on("mouseout", function() {
      this.closePopup();
    });

    return aMarker;
  }

  /**
   * Merge a style object { width: "100%" } into the default leaflet styles and
   * return it
   *
   * @param styles
   */
  public static mergeAndGetStyles(styles?) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return {
      height: "calc(100vh - 65px)",
      width: "80%",
      display: "flex",
      flex: "1 1 auto",
      ...styles,
    };
  }
}
