import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "add-location-dialog",
  templateUrl: "addLocationDialog.component.html",
  styleUrls: ["./addLocationDialog.component.scss"],
})
export class AddLocationDialogComponent implements OnInit {
  public latitude = 0;
  public longitude = 0;

  public form: any = {
    name: "VIA",
    position: {
      latitude: 0,
      longitude: 0,
    },
    streetName: "",
    city: "",
    country: "",
    zipcode: "",
  };

  public constructor(private dialogRef: MatDialogRef<AddLocationDialogComponent>) {}

  public ngOnInit() {
    this.dialogRef.keydownEvents().subscribe((events) => {
      if (events.keyCode === 13) {
        this.setGpsData();
      }
    });
  }

  public setGpsData() {
    this.dialogRef.close({ ...this.form, latitude: Number(this.latitude), longitude: Number(this.longitude) });
  }

  public save() {
    this.setGpsData();
  }

  public close() {
    this.dialogRef.close();
  }
}
