import { Component } from "@angular/core";

// 3rd party addons
import { Select } from "@ngxs/store";
import { SettingsState } from "app/shared/settings/settings.state";
import { Observable } from "rxjs";
import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { isDevMode } from "@angular/core";

@Component({
  selector: "home", // <home></home>
  templateUrl: "./home.component.html",
})
export class HomeComponent extends StateContainerComponent {
  @Select(SettingsState.title) private stateTitle: Observable<string>;
  public title = "";

  public constructor() {
    super();
  }

  public showIfDevMode() {
    return isDevMode();
  }

  /**
   * Map your properties given a state
   */
  public mapStateToProps = () => {
    this.bindSelectorToProperty(this.stateTitle, "title");
  };
}
