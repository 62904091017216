import { State, Selector, StateContext, Action, Store } from "@ngxs/store";

import { ToastrService } from "app/infrastructure/toastr/toastr.service";
import { HttpClient, HttpResponse, HttpParams } from "@angular/common/http";
import RoutingStateModel from "./models/RoutingStateModel";
import { CalculateRoute } from "./actions/CalculateRoute";
import { CallStatus } from "app/models/enums/httpCallStatus";
import { AppSettings } from "app/infrastructure/appsettings";
import { tap } from "rxjs/operators";
import { CalculatedRoute } from "../models/calculatedRoute";
import { TunnelRestriction } from "../models/enums/tunnelRestriction";
import { SpeedProfile } from "../models/enums/speedProfile";
import { EmissionStandard } from "../models/enums/emissionStandard";
import { GoodsType } from "../models/enums/goodsType";
import { VehicleType } from "../models/enums/vehicleType";
import { SetRoutingPoints } from "./actions/SetRoutingPoints";
import { UpdateRoutingFilter } from "./actions/UpdateRoutingFilter";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@State<RoutingStateModel>({
  name: "routing",
  defaults: {
    points: [],
    operationState: CallStatus.succeeded,
    filter: {
      tunnelRestriction: TunnelRestriction.A,
      speedProfile: SpeedProfile.Truck40T,
      emissionStandard: EmissionStandard.EURO_5,
      goodsType: GoodsType.NONE,
      vehicleType: VehicleType.TRU,
      drawRoute: true,
      tollDate: new Date(),
    },
  },
})
@Injectable()
export class RoutingState {
  public constructor(
    private toastrService: ToastrService,
    private http: HttpClient,
    private store: Store,
    public translateService: TranslateService
  ) {}

  @Selector()
  public static operationState(state: RoutingStateModel) {
    return state.operationState;
  }

  @Selector()
  public static filter(state: RoutingStateModel) {
    return state.filter;
  }

  @Selector()
  public static points(state: RoutingStateModel) {
    return state.points;
  }

  @Selector()
  public static calculatedRoute(state: RoutingStateModel) {
    return state.calculatedRoute;
  }

  /**
   * Calculate a route
   *
   * @param ctx
   * @param action
   */
  @Action(CalculateRoute)
  public CalculateRoute(ctx: StateContext<RoutingStateModel>, action: CalculateRoute) {
    const { root, calculateRoute } = AppSettings.settings.BackendPaths;

    let request = {};
    // if action has a request
    if (action.request) {
      request = action.request;
      const {
        points,
        tollDate,
        tunnelRestriction,
        speedProfile,
        emissionStandard,
        goodsType,
        vehicleType,
      } = action.request;

      // patch state with action values
      ctx.patchState({
        points,
        operationState: CallStatus.pending,
        filter: {
          ...ctx.getState().filter,
          ...{ tollDate, tunnelRestriction, speedProfile, emissionStandard, goodsType, vehicleType },
        },
      });
    } else {
      const { filter, points } = ctx.getState();
      request = { ...filter, points };

      if (points.length < 2) {
        return null;
      }
    }

    // include map properties (because we always show a map this can be
    // hard coded)
    let params = new HttpParams();
    params = params.set("includeMapProperties", "true");

    // do the actual call
    return this.http
      .post(`${root}/${calculateRoute}`, request, {
        params,
        observe: "response",
      })
      .pipe(
        tap(
          (response: HttpResponse<CalculatedRoute>) => {
            ctx.patchState({
              calculatedRoute: response.body,
              operationState: CallStatus.succeeded,
            });
          },
          () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.handleError.bind(this)();
            ctx.patchState({ operationState: CallStatus.failed });
          }
        )
      );
  }

  /**
   * set points
   *
   * @param ctx
   * @param action
   */
  @Action(SetRoutingPoints)
  public UpdateRoutingPoints(ctx: StateContext<RoutingStateModel>, action: SetRoutingPoints) {
    ctx.patchState({
      points: action.routingPoints,
    });

    if (action.refresh) {
      // calculate the route again on change
      this.store.dispatch(new CalculateRoute());
    }
  }

  /**
   * Update filter, overwriting the old values
   *
   * @param ctx
   * @param action
   */
  @Action(UpdateRoutingFilter)
  public UpdateRoutingFilter(ctx: StateContext<RoutingStateModel>, action: UpdateRoutingFilter) {
    ctx.patchState({
      filter: { ...ctx.getState().filter, ...action.routingFilter },
    });
    if (action.refresh) {
      // calculate the route again on change
      this.store.dispatch(new CalculateRoute());
    }
  }

  /**
   * give a toast on error
   *
   * @param error
   */
  public handleError() {
    this.toastrService.error(this.translateService.instant("Unable to calculate route."));
  }
}
