import { APPROUTES } from "./app.routes.strings";
import { NavItem } from "./infrastructure/sidenav/nav-item";

export const NavItems: NavItem[] = [
  {
    icon: "home",
    text: "nav.home",

    routerLink: APPROUTES.home,
    exact: true,
    requiresAuthorization: false,
  },
  {
    icon: "map",
    text: "nav.routing",
    routerLink: APPROUTES.routing,
    exact: false,
    requiresAuthorization: true,
  },
  {
    icon: "pin_drop",
    text: "nav.geo",
    routerLink: APPROUTES.geo,
    exact: false,
    requiresAuthorization: true,
  },
  {
    icon: "my_location",
    text: "nav.locations",
    routerLink: APPROUTES.locations,
    exact: false,
    requiresAuthorization: true,
  },
];
