import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Layer, FeatureGroup } from "leaflet";
import { SimpleAddress } from "../routing/models/simpleAddress";
import { LeafletService } from "app/services/leaflet.service";
import { HotkeysService, Hotkey } from "angular2-hotkeys";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.css"],
})
export class LocationsComponent {
  public mapReference: L.Map;
  public mergeAndApplyStyles = LeafletService.mergeAndGetStyles;
  public getLeafletOptions = LeafletService.getLeafletOptions;
  public layers: Layer[] = [];
  public showUrlParseErrorMessage = false;

  public constructor(
    private route: ActivatedRoute,
    private hotkeyService: HotkeysService,
    private toastrService: ToastrService,
    public translateService: TranslateService
  ) {
    // add a hotkey for ctrl + v
    this.hotkeyService.add(
      new Hotkey("ctrl+v", (): boolean => {
        // read the text from the clipboard
        navigator.clipboard
          .readText()
          .then((text) => {
            // check whether url contains request=?
            // remove the url if the pasted text includes it
            let encodedModel = text;
            const urlDeterminer = "?request=";
            if (text.includes(urlDeterminer)) {
              const splitContents = text.split(urlDeterminer);
              if (splitContents.length > 1) {
                encodedModel = splitContents[1];
              }
            }

            // try parse
            try {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const model = JSON.parse(atob(encodedModel));
              // if parse succeeded draw the map
              this.buildMapFromModel(model);
              this.showUrlParseErrorMessage = false;
            } catch (e) {
              // if we can't parse it we get an error
              this.toastrService.warning(translateService.instant("locations.modelNotValid"));
            }
          })
          .catch(() => {
            // maybe user didn't grant access to read from clipboard
            this.toastrService.error(translateService.instant("permissions.clipboard"));
          });
        return false; // Prevent bubbling
      })
    );
  }

  /**
   * Draw the map given addresses and some meta info
   *
   * @param markers addresses of which to show markers
   * @param type type of icon
   * @param zoom zoomlevel
   */
  private drawMap(markers: SimpleAddress[], type: string, zoom: number) {
    this.layers = [];
    markers.forEach((position) => {
      // push the marker to the layers
      this.layers.push(
        LeafletService.getMarkerWithBasicPopup(
          position.position.latitude,
          position.position.longitude,
          position.name,
          `/assets/images/markers/${type}.png`
        )
      );
    });

    const bounds = new FeatureGroup(this.layers).getBounds();
    this.mapReference.fitBounds(bounds, { padding: [20, 20] });

    // if the map is zoomed in more than the user requested set zoom to request
    // if the map is zoomed in less than the user requested the icons would fall of the map if we add more zoom
    if (this.mapReference.getBoundsZoom(bounds) > zoom) {
      this.mapReference.setZoom(zoom);
    }
  }

  public onMapReady(map: L.Map) {
    this.mapReference = map;
    this.route.queryParams.subscribe((params) => {
      // if query params contain a longitude or latitude use it
      if (params.request) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const model = JSON.parse(atob(params.request));
          this.buildMapFromModel(model);
        } catch (e) {
          this.showUrlParseErrorMessage = true;
        }
      }
    });
  }

  /**
   * build the url request from a parsed json model
   *
   * @param model parsed json (dynamic, any)
   */
  private buildMapFromModel(model: { points: SimpleAddress[]; icon: string; zoom: number }) {
    if (model.points) {
      const drawablePoints = model.points;
      const type = model.icon ? model.icon : "pin";
      const zoom = model.zoom ? model.zoom : 14;
      this.drawMap(drawablePoints, type, zoom);
    }
  }
}
