import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule, PreloadAllModules } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HotkeyModule } from "angular2-hotkeys";
import { FlexLayoutModule } from "@angular/flex-layout";
import { OidcSecurityService, OidcConfigService, AuthModule } from "angular-auth-oidc-client";
import "../styles/styles.scss";

// 3rd party Localization
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { ToastrModule } from "ngx-toastr";

import { AmazingTimePickerModule } from "amazing-time-picker";
import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";

import { LeafletModule } from "@asymmetrik/ngx-leaflet";

import { InforitLibraryModule } from "@inforit/inforit-filter-menu"; // This needs a rename

// Platform and Environment providers/directives/pipes
import { environment } from "environments/environment";
import { ROUTES } from "./app.routes";

// App is our top level component
import { AppComponent } from "./app.component";
import { HomeComponent } from "./features/home";

// Dialog's
import { AddLocationDialogComponent } from "./features/routing/dialogs/addLocationDialog.component";

// import hammer for material slides
import "hammerjs";
import "../styles/styles.scss";

import { ToolbarComponent } from "./shared/toolbar/toolbar.component";

import { SidenavComponent } from "./infrastructure/sidenav/sidenav.component";

import { NavButtonComponent } from "./infrastructure/sidenav/nav-button/nav-button.component";
import { SidenavService } from "./infrastructure/sidenav/side-nav-service.service";
import { RequiresAuthorizationPipe } from "./infrastructure/auth/pipes/requires-authorization.pipe";
import { IdentityMenuComponent } from "./infrastructure/auth/components/identity-menu/identity-menu.component";

import { DefaultToastComponent } from "./infrastructure/toastr/models/default.toast";

import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { SettingsState } from "./shared/settings/settings.state";
import { AuthenticationState } from "./infrastructure/auth/state/auth.state";
import { HomeState } from "./features/home/state/home.state";
import { AppSettings } from "./infrastructure/appsettings";

// auth components
import { UnauthorizedComponent } from "./infrastructure/oidc/unauthorized/unauthorized.component";
import { AuthGuardService } from "./infrastructure/oidc/auth-guard.service";
import { AuthInterceptor } from "./infrastructure/oidc/auth.interceptor";
import { GeocodeComponent } from "./features/geocode/geocode.component";
import { GeocodeState } from "./features/geocode/state/geocode.state";
import { RoutingComponent } from "./features/routing/routing.component";
import { RoutingState } from "./features/routing/state/routing.state";
import { LocationsComponent } from "./features/locations/locations.component";
import { LeafletService } from "./services/leaflet.service";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatListModule } from "@angular/material/list";

// initialize the app settings
export function initializeSettings(appSettings: AppSettings) {
  return () => appSettings.load();
}

// OIDC config
export function loadConfig(appSettings: AppSettings) {
  return () => appSettings.loadStsConfig();
}

// Localization | AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    HomeComponent,
    SidenavComponent,
    ToolbarComponent,
    NavButtonComponent,
    RequiresAuthorizationPipe,
    IdentityMenuComponent,
    DefaultToastComponent,
    GeocodeComponent,
    UnauthorizedComponent,
    RoutingComponent,
    AddLocationDialogComponent,
    LocationsComponent,
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    FlexLayoutModule,
    AmazingTimePickerModule,
    SatDatepickerModule,
    SatNativeDateModule,
    InforitLibraryModule,
    LeafletModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HotkeyModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules,
    }),
    NgxsModule.forRoot([SettingsState, AuthenticationState, HomeState, GeocodeState, RoutingState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      collapsed: true,
      disabled: environment.production,
    }),
    AuthModule.forRoot(),
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PerfectScrollbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatChipsModule,
  ],
  /**
   * Expose our Services and Providers into Angulars dependency injection.
   */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  providers: [
    environment.ENV_PROVIDERS,
    AppSettings,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSettings,
      deps: [AppSettings],
      multi: true,
    },
    OidcSecurityService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [AppSettings],
      multi: true,
    },
    SidenavService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    LeafletService,
  ],
  entryComponents: [DefaultToastComponent, AddLocationDialogComponent],
})
export class AppModule {}
